import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import {Advantages} from '../../components/Advantages/Advantages'
import {Callback} from '../../components/Callback/Callback'
import { Scheme } from '../../components/Scheme/Scheme'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import {Practical} from '../../components/Practical/Practical'
import {Certificates} from '../../components/Certificates/Certificates'
import { Portfolio } from '../../components/Portfolio/Portfolio'
import { Sphere } from '../../components/Sphere/Sphere'
import {Services} from '../../components/Services/Services'

export const Main = ({isOpen, popupOpen, isImageOpen, isModalPortfolio}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen ={isOpen}
            />
            <SliderAdvantages/>
            <AboutUs
            />
            <Services/>
            <Advantages/>
            <Sphere/>
            <Portfolio
                isImageOpen={isModalPortfolio}
            />
            <Certificates
                isImageOpen={isImageOpen}
            />
            <Scheme
                isOpen ={isOpen}
            />
            <Callback
                popupOpen={popupOpen}
            />
            <Practical/>
        </section>
    )
}